import ReactHtmlParser from 'react-html-parser';

export default (text) => {
  // Replace forward slashes with <br/> and backslashes with <wbr/>
  text = text.replace(/\//g, ' <br/>').replace(/\\/g, '<wbr/>');

  // Replace text inside backticks with a span with classname 'highlight'
  text = text.replace(/`([^`]*)`/g, '<span class="highlight">$1</span>');

  // Parse the HTML and return the result
  return ReactHtmlParser(text);
};
